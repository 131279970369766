<template>
  <ASmoothList class="o-microcart-rules">
    <div
      v-for="item of items"
      :key="item.rule_id"
      class="o-microcart-rules__item"
    >
      <span>{{ item.description }}</span>
      <button @click="revert(item.rule_id)">{{ $t('Revert rule') }}</button>
    </div>
  </ASmoothList>
</template>

<script>
import ASmoothList from 'theme/components/atoms/a-smooth-list.vue';

export default {
  name: 'OMicrocartGifts',
  components: {
    ASmoothList
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {},
  methods: {
    revert (id) {
      this.$emit('revert', id)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/breakpoints";

  .o-microcart-rules {
    background: var(--blue-light2);

    &__item {
      display: flex;
      padding: 1.2rem;

      span {
        font-size: var(--font-size-13);
        font-weight: var(--font-medium);
        flex: 1
      }

      button {
        border: none;
        background: none;
        outline: none;
        font-size: var(--font-size-13);
        font-weight: var(--font-bold);
        padding: 0;
        color: var(--orange);
        cursor: pointer;
      }
    }
  }
</style>
