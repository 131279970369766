<template>
  <button
    class="a-add-to-cart-from-group-wishlist"
    data-transaction-name="Add Wishlist Group To Cart"
    @click="openModalWishlistToCart"
  >
    {{ $t('Wishlist') }}

    <template v-if="isLoggedIn">
      ({{ this.allWishlistItems.length }})
    </template>
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'AAddToCartFromWishlist',
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters({
      allWishlistItems: 'wishlist/getWishlistItems'
    })
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal',
      closeModal: 'closeModal'
    }),
    openModalWishlistToCart () {
      const isLoggedIn = this.isLoggedIn;
      const isMobile = this.$mq === 'xs';
      const hasWishlistItems = this.allWishlistItems.length !== 0;

      if (!isLoggedIn) {
        this.openModal({ name: ModalList.Auth, payload: 'auth' });
        return
      }

      if (isMobile) {
        this.$store.dispatch('ui/setMicrocart');
        this.$router.push('/cart/wishlist');
        return;
      }

      if (hasWishlistItems) {
        this.openModal({ name: ModalList.GroupWishlistToCart, payload: null });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a-add-to-cart-from-group-wishlist {
  font-size: var(--spacer-14);
  cursor: pointer;
  display: inline-block;
  color: var(--orange);
  padding: 0;
  outline: none;
  background: none;
  border: none;
}
</style>
