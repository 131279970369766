<template>
  <div class="m-microcard-empty">
    <span class="m-microcard-empty__banner-image" />
    <SfHeading
      :title="$t('Your bag is empty')"
      :subtitle="$t('To place an order, add items to your cart')"
      :level="2"
      class="m-microcard-empty__heading"
    />
    <SfButton
      class="sf-button--base sf-button--primary m-microcard-empty__button"
      @click.native="startShopping"
    >
      {{ $t("Back to shopping") }}
    </SfButton>
  </div>
</template>

<script>
import { SfHeading, SfButton } from '@storefront-ui/vue';
export default {
  name: 'MMicrocartEmpty',
  components: {
    SfHeading,
    SfButton
  },
  methods: {
    startShopping () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/fonts";

.m-microcard-empty {
  --heading-subtitle-margin: 0 0 var(--spacer-20) 0;
  --heading-title-font-size: var(--font-xl);
  --heading-title-margin: 0 0 var(--spacer-15) 0;
  --heading-title-color: var(--dark-gray);
  --heading-title-font-weight: var(--font-semibold);
  --heading-subtitle-font: var(--font-normal), var(--font-base), 1.21rem;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  margin: auto;
  background: var(--white);

  @media (min-width: $tablet-min) {
    padding: var(--spacer-15) 0 0 0;
    min-height: 25rem;
  }

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &-image {
      width: 10.25rem;
      height: 10.25rem;
      border: 2.125rem solid rgba(235, 103, 71, .1);
      box-sizing: border-box;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: var(--spacer-40);
      position: relative;

      @include font-icon(var(--icon-cart));

      &:before {
        font-size: var(--font-size-40);
        color: var(--black);
      }
    }
  }

  &__button {
    margin: 0 auto;
    min-width: 240px;
  }

  &__heading {
    padding: 0 var(--spacer-30);
  }
}

::v-deep {
  .sf-heading__title {
    --heading-title-font-line-height: 1.564rem;
  }

  .sf-heading__subtitle{
    --heading-subtitle-color: var(--black);
    --heading-subtitle-font-weight: var(--font-normal);
    font-weight: var(--font-normal);
    line-height: var(--font-size-19);
    --heading-subtitle-margin: 0 0 var(--spacer-40) 0;
  }
}
</style>
