<template>
  <ASmoothList class="m-microcart-list">
    <div
      v-if="isShowHeader"
      class="m-microcart-list__head"
      :class="classHeader"
      key="header"
    >
      <slot />
    </div>

    <MProductInCart
      v-for="(product) in list"
      class="m-microcart-list__item o-microcart-panel__element"
      :key="product.id"
      :product="product"
      :is-gift="isGift"
      :is-new-post="isNewPost"
      :cart-states="cartStates"
      :product-stock="productStock"
      :product-related="productRelated"
      :cart-sqpp="cartSqpp"
      @open-promo="openPromoModal"
      @remove="removeCartItem"
    />
  </ASmoothList>
</template>

<script>
import ASmoothList from 'theme/components/atoms/a-smooth-list';
import MProductInCart from 'theme/components/molecules/m-product-in-cart';
import { mapActions } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';

export default {
  components: {
    ASmoothList,
    MProductInCart
  },
  props: {
    classHeader: {
      type: String,
      default: () => ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    isShow: {
      type: Boolean,
      default: () => true
    },
    isGift: {
      type: Boolean,
      default: () => false
    },
    isNewPost: {
      type: Boolean,
      default: () => false
    },
    isLastProductInCart: {
      type: Boolean,
      required: true
    },
    cartSqpp: {
      type: Array,
      default: () => ([])
    },
    productStock: {
      type: Array,
      default: () => []
    },
    cartStates: {
      type: Object,
      required: true
    },
    productRelated: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isShowHeader () {
      return this.items.length && this.$slots['default']
    },
    list () {
      return this.isShow ? this.items : []
    }
  },
  methods: {
    ...mapActions({
      setProductComment: 'cart/setProductComment',
      addProductComment: 'cart/addProductComment',
      removeCartItem: 'cart/removeItem',
      updateQuantity: 'cart/updateQuantity',
      openModal: 'ui/openModal'
    }),
    openPromoModal (label) {
      return this.openModal({
        name: ModalList.CartPopupPromotion,
        payload: {
          title: label.promo_name,
          description: label.promo_decription
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-microcart-list {
  &__head {
    position: relative;
  }
}

</style>
