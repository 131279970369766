<template>
  <div
    class="m-product-in-cart-replacements"
    :class="{ 'control-buttons': hasControlButtons }"
  >
    <lazy-hydrate when-idle>
      <MProductCarousel
        :products="replacements"
        :slider-settings="sliderSettings"
        @linkClicked="linkClicked"
        arrow-color="white"
        is-white-card
        :is-replacement="true"
        @replaceProduct="replaceHandler"
      />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import MProductCarousel from 'theme/components/molecules/m-product-carousel';
import { BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG } from 'theme/helpers/breakpoints';

export default {
  name: 'MProductInCartReplacements',
  props: {
    replacements: {
      type: Array,
      default: () => ([])
    },
    product: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  components: {
    MProductCarousel,
    LazyHydrate
  },
  data () {
    return {
      qty: 1,
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        perView: 4,
        gap: 10,
        dragThreshold: false,
        breakpoints: {
          [BREAKPOINT_LG - 1]: {
            perView: 4
          },
          [BREAKPOINT_MD - 1]: {
            perView: 3.5
          },
          [BREAKPOINT_SM - 1]: {
            perView: 2.3,
            swipeThreshold: false,
            dragThreshold: false
          }
        }
      }
    }
  },
  computed: {
    sliderPerPage () {
      return Math.ceil(this.replacements.length / this.sliderSettings.perView)
    },
    hasControlButtons () {
      return this.sliderPerPage > 1
    }
  },
  methods: {
    async replaceHandler (replacement) {
      this.$bus.$emit('show-cart-spinner', true)
      await this.$store.dispatch('cart/addItem', {
        productToAdd: Object.assign({}, replacement, { qty: this.qty })
      });
      await this.$store.dispatch('cart/removeItem', { product: this.product });
      this.$bus.$emit('show-cart-spinner', false)
    },
    linkClicked () {
      this.$store.dispatch('ui/setMicrocart', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-product-in-cart-replacements {
  padding-top: var(--spacer-36)!important;
  margin-top: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ::v-deep {
    .sf-product-card {
      padding: 0 0 20px 0;

      &__hidden-basket {
        visibility: visible;
        opacity: 1;
      }
    }

    .product-carousel .sf-carousel__controls {
      right: var(--spacer-10);
      top: -0.2rem;
    }

    .sf-price__old {
      margin-right: 0;
    }

    .sf-price__special {
      font-size: var(--spacer-15);
      margin-left: var(--spacer-6);
    }
  }
}

.control-buttons {
  padding-top: var(--spacer-50);
}
</style>
