<template>
  <div class="m-discount-totals">
    <ASmoothReflow class="m-discount-totals__discount-body">
      <button
        v-if="discountsTotal > 0"
        class="m-discount-totals__discount"
        :class="{ 'm-discount-totals__discount--active': showDiscount }"
        @click="toggleDiscount"
      >
        {{ $t('Discount') }}
        <span class="dropdown-chevron"></span>
        <span class="m-discount-totals__discount-summary">{{ -discountsTotal | price }}</span>
      </button>

      <div
        v-if="showDiscount"
        class="m-discount-totals__discount-content"
      >
        <SfProperty
          v-for="discount of discountList"
          :key="discount.code"
          :name="discount.label"
          :value="discount.amount | price"
          class="sf-property--full-width m-discount-totals__discount-item"
        >
          <template #name="{name}">
            <span class="sf-property__name">
              {{name}}
              <span
                v-if="promoModalEnable && discount.promotion_title && discount.promotion_description"
                class="m-discount-totals__icon"
                @click="openPromoModal(discount)"
              ></span>
            </span>
          </template>
        </SfProperty>
      </div>
    </ASmoothReflow>
  </div>
</template>

<script>
import { SfProperty } from '@storefront-ui/vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import { mapActions } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'MDiscountTotals',
  components: {
    SfProperty,
    ASmoothReflow
  },
  props: {
    isLarge: {
      type: Boolean,
      default: false
    },
    discounts: {
      type: Array,
      required: true
    },
    promoModalEnable: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      showDiscount: false
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    toggleDiscount () {
      this.showDiscount = !this.showDiscount
    },
    openPromoModal (discount) {
      this.openModal({
        name: ModalList.CartPopupPromotion,
        payload: {
          title: discount?.promotion_title,
          description: discount?.promotion_description
        }
      });
    }
  },
  computed: {
    discountsTotal () {
      return this.discountList.reduce((a, b) => a + b.amount, 0)
    },
    discountList () {
      return this.discounts.filter(i => i.code !== 'coupon') || []
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

.m-discount-totals {
  &__discount {
    cursor: pointer;
    display: flex;
    padding: 0;
    margin: 0;
    background: none;
    width: 100%;
    border: 0;
    align-items: center;
    font-size: var(--font-size-16);
    color: var(--orange);

    &--active {
      .dropdown-chevron:after {
        transform: rotate(-135deg);
      }
    }

    .dropdown-chevron:after {
      transition: all 200ms;
      border-color: var(--orange);
    }

    &-summary {
      margin-left: auto;
    }

    &-body {
      margin-bottom: var(--spacer-16);

      .m-discount-totals__discount-item {
        margin-bottom: 0;
        --property-name-font-size: var(--font-size-12)!important;
        --property-value-font-size: var(--font-size-12)!important;
      }
    }

    &-item, &-item.property {
      margin: 0 0 var(--spacer-10);
      --property-name-color: var(--orange)!important;
      --property-value-color: var(--orange)!important;
    }

    &-content {
      padding-left: var(--spacer-16);
      padding-top: var(--spacer-10);
      display: flex;
      flex-direction: column;
      gap: var(--spacer-10);

      --property-name-content: '';
    }
  }

  ::v-deep .sf-property__value {
    white-space: nowrap;
  }

  &__icon {
    cursor: pointer;
    @include font-icon(var(--icon-info-outline));
  }
}
</style>
