<template>
  <div />
</template>
<script>
import config from 'config';
import { mapActions, mapGetters } from 'vuex';
import { eSputnikEvent } from 'theme/helpers/es';
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related';

export default {
  name: 'MReplacementCart',
  props: {
    products: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  computed: {
    ...mapGetters({
      shippingDetails: 'shipping/getShippingDetails'
    }),
    shippingDetailsShopId () {
      return this.shippingDetails.shopId
    }
  },
  watch: {
    shippingDetailsShopId: async function (newValue) {
      if (!newValue) return
      this.getEsputnikProducts()
    }
  },
  mounted () {
    this.getEsputnikProducts()
  },
  methods: {
    ...mapActions({
      findRelatedProducts: 'product/findRelatedProducts',
      setRelatedProducts: 'product/related'
    }),
    getEsputnikProducts () {
      this.products.forEach(product => {
        this.eSputnikActions(product.sku)
      })
    },
    async eSputnikActions (productSku) {
      window.eS('getRecommendations', {
        'variantId': config.esputnik?.variantId,
        'productIds': [productSku]
      }, async (error, products) => {
        if (error) {
          console.error(error);
        } else if (products.length) {
          await this.getAndStoreReplacementProducts({
            relatedProductsKey: 'replacementsCartProducts' + '_' + productSku,
            size: config?.entities?.product?.carouselSize || 12,
            onlyInStock: true,
            productSku,
            products
          })

          eSputnikEvent('ProductImpression', { products })
        }
      })
    },
    async getAndStoreReplacementProducts ({
      relatedProductsKey = '',
      size = config?.entities?.product?.carouselSize,
      onlyInStock = false,
      products = []
    }) {
      if (!products.length) return

      const sku = products.map(e => e.id) || []
      const relatedProductsQuery = prepareRelatedQuery('sku', sku);

      const response = await this.findRelatedProducts({
        query: relatedProductsQuery,
        size,
        onlyInStock
      });

      if (response) {
        this.setRelatedProducts({
          key: relatedProductsKey,
          items: response.items
        });
      }
    }
  }
};
</script>
