<template>
  <button
    class="a-add-cart-to-group-wishlist"
    data-transaction-name="Add Cart Group To Wishlist"
    @click="onClickHandler"
  >
    {{ $t('Add all to favorite') }}
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'AAddCartToWishlist',
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      getCartItems: 'cart/getCartItems'
    })
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    onClickHandler () {
      if (!this.isLoggedIn) {
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
        return
      }

      this.addProductToWishlist(this.product)
    },
    addProductToWishlist () {
      this.openModal({
        name: ModalList.WishlistManager,
        payload: {
          products: this.getCartItems,
          title: this.$t('Select list'),
          isAddFromCart: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-add-cart-to-group-wishlist {
  font-size: var(--spacer-14);
  cursor: pointer;
  display: inline-block;
  color: var(--orange);
  padding: 0;
  outline: none;
  background: none;
  border: none;
}
</style>
