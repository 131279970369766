export interface AttributeOption {
  value: string,
  label: string,
  sort_order: number
}

export default interface ProductExtensionState {
  characteristicAttributes: any,
  characteristicAttributeCodes: any,
  topCharacteristicAttributeCodes: any,
  productAttributeLabelMap: Record<number, AttributeOption>,
  inStockProductVariants: any,
  varusPerfectProducts: any
}

export interface AttributeOptionItem {
  label: string,
  value: number
}

export enum ProductVisibility {
  NotVisibleIndividually = 1,
  Catalog = 2,
  Search = 3,
  CatalogSearch = 4
}
